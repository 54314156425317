<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="ApplyCardContainer">
      <div class="step4Container">
        <a-icon
          class="topImg"
          type="check-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Register Success</div>
      </div>
    </div>
    <NewFooter />
    <LoginModal :drawerVisible="drawerVisible" :closeModal="closeModal" />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import LoginModal from '@/components/LoginModal.vue';

export default {
  name: 'ApplyCard',
  components: {
    NavHeader,
    NavHeaderMobile,
    NewFooter,
    LoginModal,
  },
  data() {
    return {
      drawerVisible: true,
    };
  },
  created() {},
  methods: {
    closeModal() {
      this.drawerVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ApplyCardContainer {
  width: 1280px;
  padding-top: 80px;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 150px;
  min-height: calc(100vh - 180px);
  @media (max-width: 960px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .step4Container {
    .topImg {
      width: 100%;
      margin: 40px auto 0;
      text-align: center;
    }
    .step4Tips {
      font-size: 28px;
      text-align: center;
      margin-top: 40px;
    }
  }
}
</style>

<template>
  <a-drawer
    title="Login"
    :width="375"
    :visible="drawerVisible"
    @close="onClose"
  >
    <div class="wrapper-left-item">
      <div class="select-label">Email</div>
      <div class="select-container select-container-address">
        <a-input
          placeholder="Email"
          :value="mail"
          size="large"
          @change="
            (e) => {
              this.mail = e.target.value;
            }
          "
        />
      </div>
    </div>
    <div class="wrapper-left-item">
      <div class="select-label">Password</div>
      <div class="select-container select-container-address">
        <a-input
          placeholder="Password"
          type="password"
          :value="password"
          size="large"
          @change="
            (e) => {
              this.password = e.target.value;
            }
          "/>
      </div>
    </div>
    <div class="resetPasswordContainer">
      <a
        class="register"
        href="javascript:void(0)"
        @click="resetPasswordClick()"
      >
        Forget your password?
      </a>
    </div>
    <a-button class="goToBtn" @click="loginCLick()" :loading="loginLoading"
      >Login</a-button
    >
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        padding: '48px 24px',
        textAlign: 'center',
        zIndex: 1,
      }"
    >
      Haven’t registered?
      <span class="register" @click="registerCLick()">Sign up now</span>
    </div>
    <a-modal
      v-model="verifyGoogleVisible"
      title="Verify"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="verifyGoogleAuthForm" @submit="handleVerifyGoogleSubmit">
        <a-form-item label="Verify Google Authenticator">
          <a-input
            class="google_input"
            placeholder="Google Authenticator Code"
            size="large"
            v-decorator="[
              'google_code',
              {
                rules: [
                  {
                    required: true,
                    len: 6,
                    message:
                      'Please enter the six-digit code from Google Authenticator',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-drawer>
</template>

<script>
import { login, getGaStatus } from '@/utils/api';

export default {
  name: 'LoginModal',
  props: {
    nav: {
      type: Boolean,
      require: false,
      default: true,
    },
    drawerVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
    routerUrl: {
      type: String,
      require: false,
      default: '',
    },
    closeModal: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      verifyGoogleAuthForm: this.$form.createForm(this, {
        name: 'verifyGoogleAuthFormName',
      }),
      loginLoading: false,
      mail: '',
      password: '',
      verifyGoogleVisible: false,
      verifyParams: {},
    };
  },
  created() {},
  methods: {
    onClose() {
      if (this.closeModal) {
        this.closeModal();
      }
    },
    submitLogin(params) {
      login(params)
        .then((res) => {
          this.loginLoading = false;
          localStorage.setItem('inst_mail', res.result.mail);
          localStorage.setItem('limit_amount', res.result.limit_amount);
          this.verifyGoogleVisible = false;
          if (this.closeModal) {
            this.closeModal();
          }
          if (this.routerUrl) {
            if (this.routerUrl === 'go0') {
              this.$router.go(0);
            } else {
              this.$router.push(`${this.routerUrl}`);
            }
          } else {
            this.$router.push({
              path: '/my',
              query: {
                tab: 'fastBuy',
              },
            });
          }
        })
        .catch(() => {
          this.loginLoading = false;
        });
    },
    loginCLick() {
      if (!this.mail) {
        this.$message.error('Please enter email');
      } else if (!this.password) {
        this.$message.error('Please enter your password');
      } else {
        this.loginLoading = true;
        getGaStatus(this.mail).then((res) => {
          if (res.code === 0) {
            if (res.result.ga_enable && res.result.GABound) {
              if (this.closeModal) {
                this.closeModal();
              }
              this.verifyGoogleVisible = true;
              this.verifyParams = {
                mail: this.mail,
                password: this.password,
                login_type: '4',
              };
            } else {
              const params = {
                mail: this.mail,
                password: this.password,
                login_type: '4',
              };
              this.submitLogin(params);
            }
          }
        });
      }
    },
    handleVerifyGoogleSubmit(e) {
      e.preventDefault();
      this.verifyGoogleAuthForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...this.verifyParams,
            'ga-code': values.google_code,
          };
          this.submitLogin(params);
        }
      });
    },
    registerCLick() {
      this.$router.push('/register');
    },
    resetPasswordClick() {
      this.$router.push('/resetPassword');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.nav-header {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height;
  line-height: $header-height;
  background: #fff;
  display: none;
  @media (min-width: 960px) {
    display: inline-block;
  }

  &.bg {
    background: #fff;
    opacity: 0.96;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (max-width: 960px) {
    justify-content: center;
  }
  .nav-left-container {
    display: flex;
    .ant-dropdown-trigger {
      color: #0045ff;
      font-weight: bold;
      font-size: 16px;
    }
    .ant-menu-item-active {
      background: #0045ff;
    }
  }
  .nav-right-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.quickBtn {
  cursor: pointer;
  color: #0045ff;
  font-weight: bold;
  font-size: 16px;
}

.cardBtn {
  margin-right: 40px;
}

.img--logo {
  vertical-align: middle;
  height: 48px;
}

.nav {
  margin: 0 40px 0 120px;
  .ant-dropdown-trigger {
    margin-right: 40px;
  }
}

.nav__item {
  font-size: 16px;
  line-height: 19px;
  opacity: 0.8;
  transition: opacity 0.3s;

  & + & {
    margin-left: 48px;
  }

  &:hover {
    opacity: 1;
  }
}
.nav-menu-item-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
  }
  .changeImg {
    height: 16px;
    width: 16px;
  }
  .nav-menu-item-right {
    margin-left: 20px;
    span {
      opacity: 0.8;
      font-size: 12px;
    }
  }
}
.select-label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  opacity: 0.3;
}
.select-container-address {
  height: 50px;
}
.goToBtn {
  background: #0045ff;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
  margin-top: 20px;
}
.resetPasswordContainer {
  text-align: end;
  margin-bottom: 40px;
}
.register {
  cursor: pointer;
  color: #0045ff;
  font-size: 12px;
}
.register:hover {
  border-bottom: 1px solid #0045ff;
}
.mailSty {
  cursor: pointer;
  color: #0045ff;
}
</style>
